@import "~/scss/variables";

// Box sizing fix
html {
	box-sizing: border-box;
	background: $bg;
	color: $fg;
	font-size: 100%;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Fira Sans", sans-serif;
}

*,
*:before,
*:after { box-sizing: inherit; }

// Document Defaults
html,
body {
	height: 100%;
	width: 100%;
}

// Links
a {
	color: $red;
	text-decoration: none;

	&:hover { text-decoration: underline; }
}
